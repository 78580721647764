import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Edit from './components/Edit';
import Form from './components/Form';
import Login from './components/Login';
import Navbar from './components/Navbar';
import NotFound from './components/NotFound';
import Sucursal from './components/Sucursal';
import Statistic from './components/Statistic';

function App() {

  return (
    <div>
        <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path="/sucursal" element={<Sucursal />} />
            <Route path="/login" element={<Login />} />
            <Route path="/edit/:id" element={<Edit />} /> 
            <Route path="/add" element={<Form />} />
            <Route path="/" element={<Statistic />} /> 
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
