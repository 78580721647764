import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Image,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/slices/loggedUserReducer';
import { useNavigate } from 'react-router-dom';
import { setLogginTrue} from '../redux/slices/authReducer';
import { getData} from '../redux/slices/dataReducer';
import axios from 'axios';
import qs from 'qs';

export default function SimpleCard() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogIn = useSelector(state => state.isLogIn);
  const [value, setValue] = useState({ username: '', password: '' });

  const handleInput = event => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  function handleSubmit(e) {
    if (e.target) e.preventDefault();
    axios
      .post('https://extendeal.itps.cloud/auth/token', qs.stringify(value))
      .then(res => res.data)
      .then(data => {
        localStorage.setItem('femsa', `Bearer ${data.access_token}`);
        dispatch(setLogginTrue());
        dispatch(setUser());
        dispatch(getData());
        toast({
          title: 'Exito!',
          description: `Inicio de sección exitosa`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      })
      .catch(error => {
        toast({
          title: 'Error',
          description: `Verifique su usuario y contraseña`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  }
  useEffect(() => {
    dispatch(setUser());
    if (isLogIn) {
      navigate('/');
    }
  }, [isLogIn]);

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Inicio de sesión</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack align={'center'}>
            <Image
              src="https://itps.one/wp-content/uploads/2021/03/Logos_ITPS_one-115px.png"
              alt="Logo"
              marginBottom={'30px'}
            />
          </Stack>

          <Stack spacing={4}>
            <FormControl id="username" isRequired>
              <FormLabel>username</FormLabel>
              <Input onChange={handleInput} type="text" name="username" />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input onChange={handleInput} type="password" name="password" />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
