import {
  Box,
  Flex,
  IconButton,
  Button,
  Stack,
  Image,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  const isLogIn = useSelector((state) => state.isLogIn);

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Link to={"/"}>
            <Image
              src="https://itps.one/wp-content/uploads/2021/03/Logos_ITPS_one-115px.png"
              alt="Logo"
            />
          </Link>
        </Flex>

        {!isLogIn ? (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Link to="/login">
              <Button
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"blue.400"}
                _hover={{
                  bg: "blue.300",
                }}
              >
                Sign In
              </Button>
            </Link>
          </Stack>
        ) : (
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Menu
            </MenuButton>
            <MenuList>
              <Link to="/">
                <MenuItem>Estadisticas</MenuItem>
              </Link>
              <Link to="/sucursal">
                <MenuItem>Editar sucursales</MenuItem>
              </Link>
            </MenuList>
          </Menu>
        )}
        <ColorModeSwitcher justifySelf="flex-end" />
      </Flex>
    </Box>
  );
}
