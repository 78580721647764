import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";
import { Toast } from "@chakra-ui/react";
import axios from "axios";

const getData = createAsyncThunk("GET_DATA", () => {
  return axios
    .get("https://extendeal.itps.cloud/havanna/sucursales")
    .then((res) => res.data)
    .then((sucursales) => sucursales)
    .catch((error) => console.log("error", error));
});

const getRegister = createAsyncThunk("GET_REGISTER", () => {
  const date = new Date();
  return axios
    .get(
      `https://extendeal.itps.cloud/estadistica/date/orders-per-day?date_first=${date.getFullYear()}-${
        date.getMonth()+1
      }-1`
    )
    .then((res) => res.data)
    .catch((error) => console.log("error", error));
});
const addData = createAsyncThunk("ADD_DATA", (data) => {
  //   return axios
  //     .post("/api/members/add", {
  //       name: data.name,
  //       email: data.email,
  //       number: data.number,
  //       status: data.status,
  //     })
  //     .then((req) => req.body)
  //     .then((contact) => contact)
  //     .catch((error) => message.error(`Error: ${error.message}`, 5));
});

const deleteData = createAsyncThunk("DELETE_DATA", ({ id }) => {
  //   return axios.delete(`api/members/${id}`).then((req) => req.body);
});

const editData = createAsyncThunk("PUT_DATA", (data) => {
  //   const { name, email, id, number, status } = data;
  //   console.log("la data", data);
  //   return axios
  //     .put(`/api/members/${id}`, {name, email, number, status})
  //     .then((req) => req.body)
  //     .then((contact) => contact)
  //     .catch((error) => message.error(`Error: ${error.message}`, 5));
});

const dataReducer = createReducer([], {
  [getData.fulfilled]: (state, action) => action.payload,

  [getRegister.fulfilled]: (state, action) => action.payload,

  [addData.fulfilled]: (state, action) => action.payload,

  [deleteData.fulfilled]: (state, action) => action.payload,

  [editData.fulfilled]: (state, action) => action.payload,
});

export { getData, getRegister, dataReducer, addData, deleteData, editData };
