import {
  Flex,
  Box,
  Button,
  HStack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Tfoot,
  Thead,
  Td,
  Th,
  Tr,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getData, deleteData } from '../redux/slices/dataReducer';
import axios from 'axios';
import DeleteButton from '../common/DeleteButton';

export default function TableSucursal() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [change, setChange] = useState(true);
  const isLogIn = useSelector(state => state.isLogIn);
  const user = useSelector(state => state.loggedUser);

  const token = localStorage.getItem('femsa');

  function handleDelete(id) {
    axios
      .delete(`https://extendeal.itps.cloud/havanna/destroy/${id}`, {
        headers: { authorization: token },
      })
      .then(res => {
        setChange(!change);
        toast({
          title: 'Exito!',
          description: `Se elimino correctamente`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error =>
        toast({
          title: 'No tienes Permisos.',
          description: `${error}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      );
  }

  useEffect(() => {
    if (isLogIn) {
      dispatch(getData());
    } else {
      navigate('/login');
    }
  }, [change]);

  const data = useSelector(state => state.data);

  return (
    <Flex
      minH={'100vh'}
      marginTop={'10vh'}
      align={'flex-start'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Box minW={'150vh'}>
        <TableContainer>
          <Link to={'/add'}>
            <Flex alignItems="center" justify={'flex-end'}>
              <Button
                display={{ base: 'none', md: 'inline-flex' }}
                fontSize={'sm'}
                fontWeight={600}
                color={'white'}
                bg={'blue.400'}
                _hover={{
                  bg: 'blue.300',
                }}
              >
                Agregar
              </Button>
            </Flex>
          </Link>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Sucursal</Th>
                <Th>Usuario</Th>
                <Th>Pass</Th>
                <Th>Dirección de entrega</Th>
                <Th>Dirección de entrega Femsa</Th>
                <Th>Email extendeal</Th>
                <Th>Email femsa</Th>
                <Th>Acción</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((data, id) => (
                <Tr key={id}>
                  <Td>{data.Sucursal}</Td>
                  <Td>{data.Usuario}</Td>
                  <Td>{data.Pass}</Td>
                  <Td>{data.Direccion_entrega}</Td>
                  <Td>{data.Direccion_entrega_femsa}</Td>
                  <Td>{data.Email_extendeal}</Td>
                  <Td>{data.Email_femsa}</Td>
                  <Td>
                    <Flex>
                      <HStack m="2px">
                        <Button
                          borderRadius="md"
                          bg="green.400"
                          color="white"
                          px={4}
                          h={8}
                        >
                          <Link to={`/edit/${data.id}`}>Editar</Link>
                        </Button>
                        <DeleteButton handle={() => handleDelete(data.id)} />
                      </HStack>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
}
