import {
  Box,
  Flex,
  HStack,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Card from "./Card";
import ChartLine from "./ChartLine";

export default function Statistic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogIn = useSelector((state) => state.isLogIn);

  const [actual, setActual] = useState(["", [{ orders: "", date: "" }]]);
  const [past, setPast] = useState(["", [{ orders: "", date: "" }]]);
  const [sucursales, setSucursales] = useState([
    "",
    [{ orders: "", date: "" }],
  ]);
  const [detail, setDetail] = useState(["", [{ orders: "", date: "" }]]);
  const [popular, setPopular] = useState("");
  const [data, setData] = useState({
    labels: [""],
    datasets: [
      {
        data: [""],
        backgroundColor: "transparent",
        borderColor: "#073763",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
      },
    ],
  });
  const date = new Date();
  const dataByApi = async () => {
    const actualMonth = await axios
      .get(
        `https://extendeal.itps.cloud/estadistica/date/orders-per-day?date_first=${date.getFullYear()}-${
          date.getMonth() + 1
        }-1`
      )
      .then((res) => res.data);

    const pastMonth = await axios
      .get(
        `https://extendeal.itps.cloud/estadistica/date/orders-per-day?date_first=${date.getFullYear()}-${date.getMonth()}-1&date_last=${date.getFullYear()}-${
          date.getMonth() + 1
        }-1`
      )
      .then((res) => res.data);

    const orderPerSucursal = await axios
      .get(
        `https://extendeal.itps.cloud/estadistica/date/orders-per-sucursal?date_first=${date.getFullYear()}-${
          date.getMonth() + 1
        }-1`
      )
      .then((res) => res.data);

    const detailPerSucursal = await axios
      .get(
        `https://extendeal.itps.cloud/estadistica/date/orders?date_first=${date.getFullYear()}-${
          date.getMonth() + 1
        }-1`
      )
      .then((res) => res.data);

    const sucursalPopular = orderPerSucursal[0].Sucursal.split(" ")
      .slice(4)
      .join(" ");
    setPopular(sucursalPopular);
    setActual(actualMonth);
    setPast(pastMonth);
    setSucursales(orderPerSucursal);
    setDetail(detailPerSucursal);
    setData({
      labels: actualMonth[0].map((dates) => dates.date),
      datasets: [
        {
          data: actualMonth[0].map((orders) => orders.orders),
          backgroundColor: "transparent",
          borderColor: "#073763",
          pointBorderColor: "#073664",
          pointBorderWidth: 4,
        },
      ],
    });
  };

  useEffect(() => {
    if (isLogIn) {
      dataByApi();
    } else {
      navigate("/login");
    }
  }, [isLogIn]);

  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 1,
        max: 20,
        grid: {
          borderDash: [10],
        },
      },
    },
  };

  return (
    <Flex>
      <VStack w='100%'>
        <HStack w='100%'  marginLeft='50%'>
          <Card
            data={{
              title: "Pedidos mes",
              mount: actual[1][0].orders,
              date: actual[1][0].date,
            }}
          />
          <Card
            data={{
              title: "Mes pasado",
              mount: past[1][0].orders,
              date: past[1][0].date,
            }}
          />
          <Card
            data={{
              title: "Sucursal mayor pedidos",
              mount: popular,
              date: "",
            }}
          />
        </HStack>
        <ChartLine data={data} option={options} />
        <Box w="90%"  marginLeft='10%'>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Sucursales con más pedidos
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <TableContainer>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Sucursal</Th>
                        <Th>Cantidad de pedidos</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sucursales.map((data, id) => (
                        <Tr key={id}>
                          <Td>{data.Sucursal}</Td>
                          <Td>{data.orders}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Detalle de pedidos
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <TableContainer>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Sucursal</Th>
                        <Th>N° de pedido</Th>
                        <Th>Fecha</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {detail.map((data, id) => (
                        <Tr key={id}>
                          <Td>{data.Sucursal}</Td>
                          <Td>{data.Pedido}</Td>
                          <Td>{data.Fecha}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </VStack>
    </Flex>
  );
}
