import qs from 'qs';
import axios from 'axios';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function SimpleCard() {
  const toast = useToast();
  const navigate = useNavigate();
  const isLogIn = useSelector(state => state.isLogIn);
  const [value, setValue] = useState({
    Sucursal: '',
    Usuario: '',
    Pass: '',
    Direccion_entrega: '',
    Direccion_entrega_femsa: '',
    Email_extendeal: '',
    Email_femsa: '',
  });

  const token = localStorage.getItem('femsa');

  const handleInput = event => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  function handleSubmit(e) {
    if (e.target) e.preventDefault();
    
    axios
      .post('https://extendeal.itps.cloud/havanna/new/sucursal', value, {
        headers: { authorization: token },
      })
      .then(res => res.data)
      .then(data => {
        toast({
          title: 'Exito!',
          description: `Creación exitosa`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      })
      .catch(error => {
        if (error.response.status == '401')
          toast({
            title: 'No tienes Permisos.',
            description: `${error.response.statusText}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
      });
  }

  useEffect(() => {
    if (!isLogIn) {
      navigate('/login');
    }
  }, [isLogIn]);

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} minW="50%">
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Añadir Sucursal</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack direction={['column', 'row']} spacing="24px">
            <FormControl id="Sucursal">
              <FormLabel>Sucursal</FormLabel>
              <Input onChange={handleInput} type="text" name="Sucursal" />
            </FormControl>
          </Stack>
          <Stack direction={['column', 'row']} spacing="24px">
            <FormControl id="Usuario">
              <FormLabel>Sucursal</FormLabel>
              <Input onChange={handleInput} type="text" name="Usuario" />
            </FormControl>
            <FormControl id="Pass">
              <FormLabel>Pass</FormLabel>
              <Input onChange={handleInput} type="text" name="Pass" />
            </FormControl>
          </Stack>
          <Stack direction={['column', 'row']} spacing="24px" mt="5px">
            <FormControl id="Dirección de entrega">
              <FormLabel>Dirección de entrega</FormLabel>
              <Input
                onChange={handleInput}
                type="text"
                name="Direccion_entrega"
              />
            </FormControl>
            <FormControl id="Dirección de entrega Femsa">
              <FormLabel>Dirección de entrega Femsa</FormLabel>
              <Input
                onChange={handleInput}
                type="text"
                name="Direccion_entrega_femsa"
              />
            </FormControl>
          </Stack>
          <Stack spacing={4} mt="5px">
            <FormControl id="Email extendeal">
              <FormLabel>Email extendeal</FormLabel>
              <Input
                onChange={handleInput}
                type="text"
                name="Email_extendeal"
              />
            </FormControl>
            <FormControl id="Email femsa">
              <FormLabel>Email femsa</FormLabel>
              <Input onChange={handleInput} type="text" name="Email_femsa" />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
                bg={'red.400'}
                color={'white'}
                _hover={{
                  bg: 'red.500',
                }}
                onClick={() => navigate('/')}
                w="100%"
              >
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
